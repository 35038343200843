import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomDateFormatPipe } from "../pipes/custom-date-format.pipe";

@NgModule({
  imports: [CommonModule],
  providers: [  ],
  declarations: [
    CustomDateFormatPipe,
  ],
  exports: [
    CustomDateFormatPipe,
  ],
})
export class PipesModule {}
